import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import SEO from '../components/seo';
import Layout from '../components/Layout';

import * as S from '../components/Post/style';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <S.PostHeader>
      <S.PostTitle>404: Not found</S.PostTitle>
    </S.PostHeader>
    <S.MainContent>
      <p>{`Well, we can't fill every empty space.`}</p>
      <S.OhBoyWrap>
        <StaticImage
          src="../../static/assets/img/404eber.png"
          alt="Oh boy"
          placeholder="blurred"
        />
      </S.OhBoyWrap>
    </S.MainContent>
  </Layout>
);

export default NotFoundPage;
